<template lang="html">
<v-table-field v-model="summaryData" :headers="headers" title="สรุปยอดนัดหมาย" readonly>
  <template v-slot:toolBarItem>
    <v-date-field v-model="beginDate" label="ตั้งแต่วันที่" class="px-1" single-line hide-details></v-date-field>
    <v-date-field v-model="endDate" label="ถึงวันที่" class="px-1" single-line hide-details></v-date-field>
  </template>
  <template v-slot:item.date="props">
    <v-label-datetime :date-time="props.item.date" short-date></v-label-datetime>
  </template>
  <template v-slot:item.group="props">
    <ul>
      <li v-for="(item,index) in props.item.group" :key="index">
        {{ index }} - {{ item }}
      </li>
    </ul>
  </template>
  <template v-slot:item.vaccine="props">
    <ul>
      <li v-for="(item,index) in props.item.vaccine" :key="index">
        {{ index }} - {{ item }}
      </li>
    </ul>
  </template>
</v-table-field>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    summaryData: undefined,
    beginDate: undefined,
    endDate: undefined,
    headers: [
      {
        text: 'วันที่นัด',
        value: 'date',
        class: 'body-2'
      },
      {
        text: 'จำนวนนัดทั้งหมด',
        value: 'total_appointed',
        class: 'body-2'
      },
      {
        text: 'นัดหมายแยกตามกลุ่ม',
        value: 'group',
        class: 'body-2'
      },
      {
        text: 'นัดหมายแยกตามชนิดวัคซีน',
        value: 'vaccine',
        class: 'body-2'
      },
    ]
  }),
  watch: {
    beginDate: function() {
      this.endDate = undefined
      this.getSummaryData()
    },
    endDate: function() {
      this.getSummaryData()
    }
  },
  methods: {
    getSummaryData() {
      let loadPromise = api.post(['Covid19','VaccineController','getAppointments'],{beginDate: this.beginDate,endDate: this.endDate}).then((returnData)=>{
        if (returnData.success) {
          this.summaryData = returnData.data
        }
      })
      .catch((e)=> void e)

      this.$loading('Loading',loadPromise)
    }
  },
  beforeMount() {
    this.beginDate = helperDateTime.today().format('YYYY-MM-DD')
    this.endDate = helperDateTime.today().add(7,'days').format('YYYY-MM-DD')
  },
}
</script>

<style lang="css" scoped>
</style>
